html {
  overflow-x: hidden;
  max-width: 100vw;
}

body {
  font-size: 62.5%;
  font-family: "Courier Prime", monospace;
  margin: 0;
}

/* Home and cart button */
.home {
  font-size: 4rem;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1;
  transition: 0.1s;
  width: 74px;
}

#cart {
  top: 150px;
  right: 20px;
}

@media screen and (min-width: 1024px) {
  #cart {
    top: 50px;
    right: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .home {
    top: 50px;
    right: 50px;
  }
}

@media (hover: hover) {
  .home:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.cart-button {
  padding-top: 8px;
  background: none;
  border: none;
}

/* Header */
header {
  background: #f0efdf;
  padding: 0 10px;
}

.header {
  padding: 0 12px;
  height: 100vh;
}

@media screen and (min-width: 1024px) {
  .header {
    max-width: 50%;
    margin: auto;
  }
}

.grid-1x3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-self: center;
  align-items: center;
}

.grid-2x1 {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}

.header__intro {
  background: #23231f;
  color: #f0efdf;
  grid-column: 1 / 3;
  padding: 20px;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  height: 296px;
  overflow: hidden;
  border-radius: 10px;
}

@media screen and (max-width: 450px) {
  .header__intro {
    font-size: 0.9rem;
    line-height: 1;
  }
}

.header__intro h1 {
  font-size: 2rem;
  display: inline-block;
}

.header__nav ul {
  list-style: none;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  gap: 10px;
  margin: 0;
  padding-left: 10px;
}

.header__nav li {
  padding: 10px;
  background: #23231f;
  color: #f0efdf;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 10px;
  white-space: nowrap;
  transition: 0.1s;
}

.header__nav li a {
  text-decoration: none;
  color: #f0efdf;
}

@media (hover: hover) {
  .header__nav li:hover {
    cursor: pointer;
    filter: invert(80%);
  }
}

.other-page-header {
  width: 100%;
  padding-top: 10px;
  border-radius: 10px;
  height: 50px;
  font-size: 2rem;
  background-color: #23231f;
  color: #f0efdf;
  text-align: center;
}

/* Sections */
section {
  background: #f0efdf;
  padding: 0 10px;
}

@media screen and (min-width: 1024px) {
  .section {
    max-width: 50%;
    margin: auto;
  }
}

section:nth-of-type(odd) {
  background: #e9e3c7;
}

.section__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f0efdf;
}

.section__container a {
  color: #f0efdf;
  text-decoration: none;
  display: block;
}

.section__title {
  text-align: center;
  margin: 0 auto;
  font-size: 3rem;
  background: crimson;
  width: 80%;
  padding: 15px 0 15px 10px;
  position: relative;
  top: 50px;
  border-radius: 10px;
  box-shadow: 3px 3px 7px #3936368c;
  overflow: hidden;
}

.section__title-green {
  background: #83c15e;
}

.section__title-blue {
  background: #54cea7;
}

.section__title-purple {
  background: #7d4c99;
}

.section__title-yellow {
  background: #d7d957;
}

.section__discription {
  background: #23231f;
  padding: 50px 20px;
  width: 90%;
  font-size: 1.5rem;
  border-radius: 10px;
  margin-bottom: 10px;
}

.section__discription h2 {
  font-size: 2rem;
  margin-bottom: 0;
  margin-top: 50px;
  letter-spacing: 20px;
  word-wrap: break-word;
  display: inline-block;
}

.section__discription ul {
  list-style: none;
  padding: 0;
  padding-top: 20px;
}

.section__discription li {
  padding: 10px 0 10px 20px;
}

.p-line {
  margin-top: 0;
  letter-spacing: 40px;
  display: block;
}

.section__emoji-inline {
  display: inline;
  padding-right: 30px;
  font-size: 2rem;
}

.section__emoji {
  font-size: 3rem;
  position: relative;
  bottom: 35px;
  left: 70%;
  margin: 0;
  max-width: 80px;
}

.section__emoji-broken {
  left: 29%;
}

/* Footer */
footer {
  background: #f0efdf;
  padding-top: 60px;
}

footer .section__discription {
  padding: 20px;
}

.footer__disclaimer {
  color: gray;
  text-align: center;
  font-size: 1rem;
}

.footer__title {
  text-align: center;
}

/* Shop */
.shop-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 50px auto;
}

@media screen and (min-width: 1024px) {
  .shop-item {
    width: 70%;
  }
}

.shop-item h2 {
  font-size: 2rem;
  letter-spacing: 1rem;
}

.shop-item p {
  font-size: 1rem;
  padding: 10px;
}

.shop-item__photo-container {
  height: 100%;
  width: 100%;
}

.shop-item__photo {
  height: 100%;
  width: 100%;
  display: none;
}

.shop-item__photo-visible {
  height: 100%;
  width: 100%;
  display: block;
}

.shop-item__photo-placeholder {
  height: 100%;
  width: 100%;
  z-index: -1;
}

.shop-item__button-prev {
  position: absolute;
  top: 45%;
  left: 5%;
  cursor: pointer;
}

.shop-item__button-prev::selection {
  background: none;
}

.shop-item__button-next {
  position: absolute;
  top: 45%;
  right: 5%;
  cursor: pointer;
}

.shop-item__button-next::selection {
  background: none;
}

.shop-item__discription {
  width: 100%;
  background: #1b1b23;
  text-align: center;
}

.shop-item__discription p {
  padding: 0 20px 20px;
}

.shop-item__bottom-border {
  width: 100%;
  height: 20px;
  background-color: #ededed;
}

.relative {
  position: relative;
}

.addToCart {
  color: #1a1813;
  margin-bottom: 30px;
  background-color: #e3959d;
  border-radius: 999px;
  padding: 5px 15px;
  transition: filter 5s;
  border: none;
}

.addToCart:hover {
  filter: hue-rotate(360deg);
}

.logo {
  font-size: 2rem;
  width: 100%;
  display: block;
  text-align: center;
  padding-top: 50px;
}

.logo a {
  color: #1b1b23;
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .logo {
    font-size: 3rem;
  }
}
